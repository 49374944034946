import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Crud from '@ksbteam/core/components/Crud';
import {connect} from "react-redux";
import {
    ticketsInit,
    ticketsSetCrudRef,
    ticketsOpenTicket,
    ticketsSetTab,
    ticketsMapSetInited,
    ticketsMapSetTickets
} from "../../actions/tickets";
import Ticket from '../Ticket';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Filters from './Filters';
import moment from 'moment';
import {DATAGRID_DEFAULT_PROPS, TICKET_COLORS} from '../../constants/tickets';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Map from './Map';
import Ymap from './Ymap';
import CreateButton from './CreateButton';

const styles = theme => ({
    root: {},
    progressWrapper: {},
    gridRoot: {
        '&.card-grid': {
            minWidth: 'auto',
            width: '100%'
        },
        '.mobile &.card-grid .react-grid-Header': {
            display: 'none'
        },
        '.mobile & .react-grid-Viewport': {
            marginTop: '-35px'
        },
        '.mobile &.card-grid *': {
            backgroundColor: 'transparent',
        },
        '&.card-grid .toolbar': {
            display: 'none',
            boxShadow: 'none',
            top: '0',
            padding: '0',
            margin: '0 0 16px 0',
            height: '40px'
        },
        '&.card-grid .toolbar .filters': {
            display: 'none'
        },
        '&.card-grid .toolbar .create-button': {
            backgroundColor: theme.palette.secondary.main,
            // pointerEvents: 'none',
            color: '#ffffff',
        },
        '&.card-grid .toolbar .create-button.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            // pointerEvents: 'none',
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none'
        },
        '.mobile &.card-grid .react-grid-Row .row-selected': {
            background: 'transparent'
        },
        '.mobile &.card-grid .react-grid-Cell': {
            padding: 0
        },
        '&.card-grid .datagrid-paper': {
            padding: 16,
            marginTop: theme.spacing()
        },
        '.mobile &.card-grid .datagrid-paper': {
            padding: '0 8px',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            marginTop: 0
        },
        '&.card-grid .react-grid-wrapper': {},
        '&.card-grid .react-grid-Canvas': {},
        '& .react-grid-Cell': {
            backgroundColor: 'transparent'
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.mobile &': {
            "left": "0",
            "width": "100%",
            "bottom": "17px",
            "zIndex": "500",
            "position": "fixed",
            "alignItems": "stretch",
            "justifyContent": "space-between",
            "display": "flex",
            "padding": '0 15px'
        }
    },
    mobileCard: {},
    mobileCardTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    mobileCardSubject: {
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 30px)'
    },
    mobileCardStatus: {
        width: 15,
        height: 15,
        borderRadius: '100%'
    },
    mobileCardBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    mobileCardType: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 60px)'
    },
    mobileCardDate: {},
    crudWrapper: {
        minWidth: 1100,
        '.mobile &': {
            minWidth: 'auto'
        }
    },
    tabs: {
        minHeight: 'auto',
        height: 35,
        display: 'inline-block',
        // marginBottom: theme.spacing(),
        '.mobile &': {
            display: 'flex'
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        },
    },
    tab: {
        minHeight: "auto",
        height: 35,
        border: '1px solid ' + theme.palette.secondary.main,
        borderRadius: '0 5px 5px 0',
        textTransform: 'none',
        padding: 0,
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '.mobile &': {
            width: '50%',
            maxWidth: '50%'
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: '#ffffff'
        },
        '&:first-child': {
            borderRadius: '5px 0 0 5px'
        }
    }
});

class Tickets extends React.Component {
    componentDidMount() {
        this.props.init();
    }

    processRows = async (rowsRaw, rows) => {
        const {classes, filters} = this.props;
        let processedRows = rows || [];
        if (!!filters.date.min || !!filters.date.max) {
            processedRows = processedRows.filter(row => {
                let date = row['service_desc-created'];
                let matchMin = !!filters.date.min ? date >= moment(filters.date.min, 'DD.MM.YYYY HH:mm') : true;
                let matchMax = !!filters.date.max ? date <= moment(filters.date.max, 'DD.MM.YYYY HH:mm') : true;
                return matchMin && matchMax;
            });
        }
        processedRows.forEach(row => {
            row['mobile_card'] = (
                <div className={classes.mobileCard}>
                    <div className={classes.mobileCardTop}>
                        <Typography component="h3" className={classes.mobileCardSubject}>
                            <strong>{row['service_desc-subject']}</strong>
                        </Typography>
                        <div className={classes.mobileCardStatus} style={{
                            backgroundColor: TICKET_COLORS[row['service_desc-status_code']]
                        }}>&nbsp;</div>
                    </div>
                    <div className={classes.mobileCardBottom}>
                        {/*<Typography color="textSecondary" gutterBottom>*/}
                        {/*{row['service_desc-assigned']}*/}
                        {/*</Typography>*/}
                        <Typography color="textSecondary" component="p" className={classes.mobileCardType}>
                            Инцидент №{row['service_desc-obj_id']}
                            {/*, {row['service_desc-incident_type']}, {row['service_desc-incident_level']}*/}
                        </Typography>
                        <Typography color="textSecondary" component="p" className={classes.mobileCardDate}>
                            {row['service_desc-created'].format('DD.MM HH:mm')}
                        </Typography>
                    </div>
                </div>
            );
        });
        return processedRows;
    };

    rowPropsGetter = (idx, data) => {
        return {
            style: {
                backgroundColor: TICKET_COLORS[data['service_desc-status_code']]
            }
        };
    };

    render() {
        const {
            classes, restClient, crudConfig, isMobileDevice, inited, setCrudRef, crudRef, ticketId, openTicket,
            tab, setTab, map, mapSetInited, mapSetTickets
        } = this.props;
        let dataGridProps = Object.assign({}, DATAGRID_DEFAULT_PROPS);
        if (isMobileDevice) {
            dataGridProps.rowHeight = 75;
            dataGridProps.onRowClick = (rowNum, data) => !!data && !!data['service_desc-obj_id']
                ? openTicket(data['service_desc-obj_id']) : false;
        }
        dataGridProps.columns.forEach(column => {
            if (column.hasOwnProperty('visible')) {
                return;
            }
            column.visible = column.key === 'mobile_card' ? isMobileDevice : !isMobileDevice;
        });
        return (
            <React.Fragment>
                {!inited && <div className={classes.progressWrapper}><LinearProgress variant="query"/></div>}
                {inited &&
                <div className={classes.root + (isMobileDevice ? ' mobile' : '')}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, value) => setTab(value)}
                        className={classes.tabs}
                    >
                        <Tab label="На схеме" value={'map'} className={classes.tab}/>
                        <Tab label="Списком" value={'list'} className={classes.tab}/>
                    </Tabs>
                    <div className={classes.toolbar}>
                        <CreateButton/>
                        <Filters/>
                    </div>
                    <div style={{display: tab === 'map' ? 'block' : 'none'}}>
                        <Ymap/>
                    </div>
                    <div
                        className={classes.crudWrapper}
                        style={{display: tab === 'list' ? 'block' : 'none'}}
                    >
                        <Crud
                            restClient={restClient}
                            config={crudConfig}
                            ref={(crud) => {
                                if (!!crud && !crudRef) {
                                    setCrudRef(crud);
                                }
                            }}
                            dataGridProps={{
                                offsetTop: isMobileDevice ? 80 : 233,
                                filters: !isMobileDevice,
                                classes: {root: classes.gridRoot},
                                onRowDoubleClick: (idx, data) => !!data && !!data['service_desc-obj_id']
                                    ? openTicket(data['service_desc-obj_id']) : false,
                                processRows: this.processRows,
                                // rowPropsGetter: this.rowPropsGetter,
                                afterLoadRows: (rows) => {
                                    if (!map.inited) {
                                        mapSetInited(true);
                                    }
                                    mapSetTickets(rows);
                                },
                                ...dataGridProps
                            }}
                        />
                    </div>
                </div>
                }
                {!!ticketId &&
                <Ticket/>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        restClient: state.page.elkUser.restClient,
        crudRef: state.tickets.crudRef,
        ticketId: state.tickets.ticketId,
        inited: state.tickets.inited,
        crudConfig: state.tickets.crudConfig,
        isMobileDevice: state.page.isMobileDevice,
        filters: state.tickets.filters,
        tab: state.tickets.tab,
        map: state.tickets.map
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(ticketsInit()),
        setCrudRef: (crudRef) => dispatch(ticketsSetCrudRef(crudRef)),
        openTicket: (id) => dispatch(ticketsOpenTicket(id)),
        setTab: (tab) => dispatch(ticketsSetTab(tab)),
        mapSetInited: (inited) => dispatch(ticketsMapSetInited(inited)),
        mapSetTickets: (tickets) => dispatch(ticketsMapSetTickets(tickets)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tickets));

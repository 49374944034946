import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import {reportsInit, reportsUpdateCurrentReport, reportsSetDataGridRef} from "../../actions/reports";
import Select from '@ksbteam/core/components/Form/Field/Select';
import DataGrid from '@ksbteam/core/components/DataGrid';

const styles = theme => ({
    selectWrapper: {
        marginBottom: theme.spacing(3)
    }
});

class Reports extends React.Component {
    componentDidMount() {
        this.props.init();
    }

    componentDidUpdate(prevProps) {
        const {dataGridRef} = this.props;
        if (!!dataGridRef && prevProps.currentReport !== this.props.currentReport) {
            dataGridRef.init();
        }
    }

    render() {
        let {
            classes, inited, reports, currentReport, restClient, dataGridRef, updateCurrentReport,
            setDataGridRef
        } = this.props;
        return (
            <React.Fragment>
                {!inited && <LinearProgress variant="query"/>}
                {inited &&
                <React.Fragment>
                    <div className={classes.selectWrapper}>
                        <Select
                            label={'Выберите отчет'}
                            id={'report_select'}
                            emptyOptionTitle={'Не выбрано'}
                            options={reports.map((report, index) => {
                                return {
                                    value: index,
                                    title: report.name
                                };
                            })}
                            onChange={updateCurrentReport}
                            value={currentReport}
                        />
                    </div>
                    {currentReport !== null && currentReport !== '' &&
                    <DataGrid
                        toolbar={false}
                        restClient={restClient}
                        columnsUrl={reports[currentReport]['link_columns']}
                        rowsUrl={reports[currentReport]['link_rows']}
                        ref={(dataGrid) => {
                            if (!!dataGrid && !dataGridRef) {
                                setDataGridRef(dataGrid);
                            }
                        }}
                    />
                    }
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inited: state.reports.inited,
        reports: state.reports.reports,
        currentReport: state.reports.currentReport,
        dataGridRef: state.reports.dataGridRef,
        showDataGrid: state.reports.showDataGrid,
        restClient: state.page.elkUser.restClient
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => dispatch(reportsInit()),
        updateCurrentReport: (e) => dispatch(reportsUpdateCurrentReport(e)),
        setDataGridRef: (dataGridRef) => dispatch(reportsSetDataGridRef(dataGridRef))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reports));

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Datetime from '@ksbteam/core/components/Form/Field/Datetime';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    ticketsUpdateFilters,
    ticketsClearFilters,
    ticketsApplyFilters,
    ticketsOpenFiltersDialog,
    ticketsCloseFiltersDialog
} from "../../actions/tickets";
import DefaultDialog from '@ksbteam/core/components/Dialog/DefaultDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        // position: 'absolute',
        zIndex: 50,
        [`@media (max-width: 1150px)`]: {
            display: 'none'
        },
        '&.mobile': {
            display: 'block',
            position: 'relative',
            right: 0
        }
    },
    dialogButtonWrapper: {
        display: 'flex'
    },
    label: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        '.mobile &': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    searchButton: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        '.mobile &': {
            marginLeft: 0,
        }
    },
    dateWrapper: {
        '.mobile &': {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
        }
    },
    filtersButtonMobile: {
        textTransform: 'none'
    }
});

function FiltersForm(props) {
    let {classes, filters, find, clearFilters, setDate, isMobileDevice} = props;
    return (
        <div className={classes.root + (isMobileDevice ? ' mobile' : '')}>
            <div className={classes.label}>Показать инциденты с</div>
            <div className={classes.dateWrapper}>
                <Datetime onChange={setDate} name={'min'} value={filters.date.min}/>
            </div>
            <div className={classes.label}>по</div>
            <div className={classes.dateWrapper}>
                <Datetime
                    onChange={setDate}
                    name={'max'}
                    value={filters.date.max}
                    needValidation={false}
                />
            </div>
            <div>
                <Button className={classes.searchButton} variant={'outlined'} onClick={find}>
                    <SearchIcon/> Искать
                </Button>
                <IconButton onClick={clearFilters} aria-label={'Очистить фильтры'}><CancelIcon/></IconButton>
            </div>
        </div>
    );
}

class Filters extends React.Component {
    setDate = (name, date, dateFormatted) => {
        const {updateFilters} = this.props;
        let filters = Object.assign({}, this.props.filters);
        filters.date[name] = dateFormatted;
        updateFilters(filters);
    };

    find = () => {
        let {isMobileDevice, applyFilters, closeFiltersDialog} = this.props;
        applyFilters();
        if (isMobileDevice) {
            closeFiltersDialog();
        }
    };

    render() {
        let {
            classes, clearFilters, openFiltersDialog, closeFiltersDialog, filters, filtersDialog, isMobileDevice
        } = this.props;
        const filtersForm = (
            <FiltersForm
                classes={classes}
                filters={filters}
                find={this.find}
                clearFilters={clearFilters}
                setDate={this.setDate}
                isMobileDevice={isMobileDevice}
            />
        );
        return (
            <React.Fragment>
                {isMobileDevice &&
                <React.Fragment>
                    <div className={classes.dialogButtonWrapper}>
                        <Button
                            className={classes.filtersButtonMobile}
                            onClick={openFiltersDialog}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            <FilterListIcon/> Фильтры
                        </Button>
                    </div>
                    <DefaultDialog
                        title={'Фильтры'}
                        onClose={closeFiltersDialog}
                        maxWidth={'sm'}
                        fullScreen={true}
                        {...filtersDialog}
                    >
                        {filtersForm}
                    </DefaultDialog>
                </React.Fragment>
                }
                {!isMobileDevice && filtersForm}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.tickets.filters,
        isMobileDevice: state.page.isMobileDevice,
        filtersDialog: state.tickets.filtersDialog
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilters: (filters) => dispatch(ticketsUpdateFilters(filters)),
        clearFilters: () => dispatch(ticketsClearFilters()),
        applyFilters: () => dispatch(ticketsApplyFilters()),
        openFiltersDialog: () => dispatch(ticketsOpenFiltersDialog()),
        closeFiltersDialog: () => dispatch(ticketsCloseFiltersDialog()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filters));

import React from 'react';
import ReactDom from 'react-dom';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import ymaps from 'ymaps';
import {
    ticketsMapCreateTicket,
    ticketsMapSetCreateCoords,
    ticketsMapSetRef, ticketsMapSetShowCreateButton,
    ticketsOpenTicket
} from "../../actions/tickets";
import {func} from "prop-types";


const styles = theme => ({
    mapContainer: {
        // minWidth: "700px",
        minHeight: "700px"
    }
});

class Ymap extends React.Component {

    clickedOutside = (e) => {
        const {mapSetCreateCoords, mapSetShowCreateButton} = this.props, {scale} = this.state;
        let rect = e.target.getBoundingClientRect();
        let createCoords = [
            Math.round((e.clientX - rect.left) / scale),
            Math.round((e.clientY - rect.top) / scale)
        ];
        mapSetCreateCoords(createCoords);
        mapSetShowCreateButton(true, [e.clientX - 23, e.clientY - 45]);
    };

    shouldComponentUpdate(nextProps, nextState) {
        const this_map = this.props.map;
        const {mapSetCreateCoords, mapSetShowCreateButton, createTicket, openTicket, map} = nextProps;
        console.log('nextProps', nextProps)
        console.log('map', map)
        const labels = [];

        const baloon_open = (item)=>{
            local_map.balloon.open(item.coords, {
                contentHeader:'Инцидент #' + item.obj_id,
                contentBody:
                    '<p>Тип:'+ item.incident_type +'</p>' +
                    '<p>'+ item.description +'</p>' +
                    '<p>Статус:'+ item.status +'</p>' +
                    '<div id="contentBody"></div>',
                contentFooter:'footer',
            }).then(()=>{
                ReactDom.render(
                    <a href='#' onClick={()=>openTicket(item.obj_id)}>Открыть</a>,
                    document.getElementById('contentBody')
                )
            })
        }


        map.tickets.forEach(function(item){

            //if(item['service_desc-obj_id'] > 600022 && item['service_desc-obj_id'] != 600024){
            if(1){

                labels.push({
                        'add': item['service_desc-add'],
                        'assigned': item['service_desc-assigned'],
                        'author': item['service_desc-author'],
                        'author_name': item['service_desc-author_name'],
                        'created': item['service_desc-created'],
                        'description': item['service_desc-description'],
                        'incident_level': item['service_desc-incident_level'],
                        'incident_type': item['service_desc-incident_type'],
                        'is_danger': item['service_desc-is_danger'],
                        'latitude': item['service_desc-latitude'],
                        'location': item['service_desc-location'],
                        'longitude': item['service_desc-longitude'],
                        'map_x': parseFloat(item['service_desc-map_x']),
                        'map_y': parseFloat(item['service_desc-map_y']),
                        'obj_id': item['service_desc-obj_id'],
                        'photo': item['service_desc-photo'],
                        'status': item['service_desc-status'],
                        'status_code': item['service_desc-status_code'],
                        'subject': item['service_desc-subject'],
                        'video': item['service_desc-video'],
                        'coords': [parseFloat(item['service_desc-map_x']), parseFloat(item['service_desc-map_y'])]
                })
                // labels.push([parseFloat(item['service_desc-map_x']), parseFloat(item['service_desc-map_y'])])
            }
        });



        const local_map = this.local_map || nextState?.localMap;
        const maps_settings = this.maps_settings || nextState?.mapSettings;

        if(!local_map || !maps_settings) return true;

        console.log('state', this.state)
        console.log('nextState', nextState)
        console.log('this', this)

        console.log('maps_settings11111', maps_settings);


        if(map.highLighted.length > 0){
            map.highLighted.forEach(function(highLightedItem, i, arr){
                labels.forEach(function(item, i, arr){
                    if(item.obj_id === highLightedItem){
                        console.log('highLightedItem', highLightedItem);


                        local_map.setCenter(item.coords, 18);
                        baloon_open(item)

                    }
                })
            })

        }

        // if(this_map.tickets !==  map.tickets){
        if(1){

            console.log('labels1', labels);

            let center_x = 43.4144095626398;
            let center_y = 39.9508607964079;




            let default_color = '#3caa3c';




            const collection = [];
            const add = function(collection, labels){
                labels.forEach(function(item, i, arr){
                    let color = default_color;
                    if(item.status_code == 'assigned'){
                        color = '#2b50d2';
                    }

                    console.log('item', item)

                    let coord_x = item.coords[0];
                    let coord_y = item.coords[1];

                    if(isNaN(coord_x) || isNaN(coord_y)){
                        return;
                    }

                    try {
                        const placemark = new maps_settings.Placemark(item.coords, {
                            balloonContent: 'цвет <strong>метка</strong>',
                            ticketId: item.obj_id
                        }, {
                            preset: 'islands#circleDotIcon',
                            iconColor: color,
                            // draggable:true
                        });


                        placemark.events.add([
                            'balloonopen'
                        ], function (e) {
                            console.log(placemark.properties._data.ticketId)
                            baloon_open(item)

                        });

                        // placemark.events.add([
                        //     'dragend'
                        // ], function (e) {
                        //     console.log(e.originalEvent.target.geometry._coordinates)
                        //
                        // });

                        collection.push(placemark);
                        console.log('placemark', placemark)
                        local_map.geoObjects
                            .add(placemark)

                    }catch(e){
                        console.log(e)
                    }

                });
            }

            const reload = function(collection, labels){
                console.log('RELOAD')
                collection.forEach(function(placemark){
                    local_map.geoObjects
                        .remove(placemark)

                    console.log('REMOVE')
                })

                // labels.push(new_coords)
                add(collection, labels)
            }

            reload(collection, labels)
        }



        return true;
    }

    constructor(props) {
        super(props);
        const {mapSetCreateCoords, mapSetShowCreateButton, createTicket, openTicket, map} = this.props;
        //'http://api-maps.yandex.ru/1.1/index.xml'
        //?key=e525ea19-e24d-48d2-b138-4be0db32e419
        ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=9d683a94-6284-47e1-ac85-2fb30f772947').then(maps_settings => {


            const labels = [
                [43.41544976809258, 39.94990056558058],
                [43.414652018168724, 39.95150989098951]
            ];

            let center_x = 43.4144095626398;
            let center_y = 39.9508607964079;



            var Layer = function () {
                var layer = new maps_settings.Layer('/maps/tail/%z/tile-%x-%y.png', {
                    tileTransparent: true,
                    zIndex: 999999
                });
                layer.getZoomRange = function () {
                    return maps_settings.vow.resolve([0, 4]);
                };
                layer.getCopyrights = ''
                return layer;
            };

            maps_settings.layer.storage.add("my#layer", Layer);
            var mapType = new maps_settings.MapType('user#customMap', ['yandex#map', "my#layer", 'yandex#map', ]);
            mapType.name = 'Схема здания';
            maps_settings.mapType.storage.add('user#customMap', mapType);

            const local_map = new maps_settings.Map('your-map-container', {
                center: [ center_x, center_y],
                zoom: 17,
                // controls: ['zoomControl'],
                type: 'user#customMap',

                scrollZoomEnabled: true,
                copyright: ""
                // options: options
            });


            // add(labels);

            local_map.events.add('click', async function (e) {
                if (!local_map.balloon.isOpen()) {
                    var coords = e.get('coords');
                    console.log(coords);

                    if(center_x - 0.005 > coords[0] || center_x + 0.005 < coords[0]){
                        return false;
                    }
                    if(center_y - 0.005 > coords[1] || center_y + 0.005 < coords[1]){
                        return false;
                    }
                    let address = '';
                    let myGeocoder = maps_settings.geocode(coords);
                    address = await myGeocoder.then(
                        function (res) {
                            let nearest = res.geoObjects.get(0);
                            address = nearest.properties.get('text');
                            return address;
                        },
                        function (err) {
                            console.log(err)
                        }
                    );

                    console.log('address11', address);
                    mapSetCreateCoords(coords);
                    mapSetShowCreateButton(true, coords);
                    createTicket(coords[0], coords[1], address)

                }
                else {
                    local_map.balloon.close();
                }
            });



            this.setState({mapSettings: maps_settings, localMap: local_map});
            // this.state = {mapSettings: maps_settings, localMap: local_map};

            console.log('maps_settings000', maps_settings);

            // this.local_map = local_map;
            // this.collection = collection;

        }).catch(error => console.log('Failed to load Yandex Maps', error));


    }

    render() {
        const {classes} = this.props;
        return (
            <div id={'your-map-container'} className={classes.mapContainer}>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        map: state.tickets.map,
        isMobileDevice: state.page.isMobileDevice,
        createCoords: state.tickets.map.createCoords,
        showCreateButton: state.tickets.map.showCreateButton,
        createButtonCoords: state.tickets.map.createButtonCoords,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        createTicket: (left, top, address) => dispatch(ticketsMapCreateTicket(left, top, address)),
        openTicket: (id) => dispatch(ticketsOpenTicket(id)),
        mapSetRef: (ref) => dispatch(ticketsMapSetRef(ref)),
        mapSetCreateCoords: (createCoords) => dispatch(ticketsMapSetCreateCoords(createCoords)),
        mapSetShowCreateButton: (show, coords) => dispatch(ticketsMapSetShowCreateButton(show, coords))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ymap));
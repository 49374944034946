import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import DefaultDialog from '@ksbteam/core/components/Dialog/DefaultDialog';
import {
    ticketsCloseTicket,
    ticketsMapSetHighLighted,
    ticketsMapChangeScale
} from "../../actions/tickets";
import {ticketSetFormRef, ticketSetFormTempValues, ticketInit} from "../../actions/ticket";
import {connect} from "react-redux";
import SimpleForm from '@ksbteam/core/components/Form/SimpleForm';
import Comments from './Comments';
import Typography from '@material-ui/core/Typography';
import CommentForm from "./Comments/CommentForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import TicketButtons from './TicketButtons';
import MapIcon from '@material-ui/icons/Map';
import History from './History';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    progressWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    editFormWrapper: {
        marginBottom: theme.spacing(4),
        '&.disabled .MuiFormLabel-root.Mui-disabled': {
            color: '#5a5a5a'
        },
        '&.disabled .MuiInputBase-root.Mui-disabled': {
            color: '#5a5a5a',
            cursor: 'not-allowed'
        }
    },
    showMapButton: {
        textTransform: 'none',
        padding: 0
    }
});

class Ticket extends React.Component {
    componentDidMount() {
        this.props.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {formRef, setFormTempValues, formTempValues} = this.props;
        if (!!formRef && !formTempValues) {
            setFormTempValues(formRef.getPreparedValues());
        }
    }

    showMapClick = () => {
        const {closeTicket, mapSetHighLighted, mapChangeScale, ticketId} = this.props;
        mapSetHighLighted([ticketId]);
        mapChangeScale(0.65);
        closeTicket();
    };

    render() {
        const {
            classes, open, ticketId, status, inited, editMode, formConfig, formRef, restClient, closeTicket,
            setFormRef
        } = this.props;
        const statusText = !!status && status.title ? `(${status.title})` : '';
        const titleDefaultText = `Инцидент ${ticketId} ${statusText}`;
        let title = (
            <React.Fragment>
                {titleDefaultText}
                <div>
                    <Button className={classes.showMapButton} onClick={this.showMapClick}>
                        <MapIcon/> {'Показать на схеме'}
                    </Button>
                </div>
            </React.Fragment>
        );
        return (
            <DefaultDialog
                open={open}
                fullScreen={true}
                onClose={closeTicket}
                title={title}
            >
                {!inited && <div className={classes.progressWrapper}><CircularProgress size={40}/></div>}
                {inited &&
                <React.Fragment>
                    <div className={classes.editFormWrapper + (!editMode ? ' disabled' : '')}>
                        <TicketButtons/>
                        <SimpleForm
                            ref={(simpleForm) => {
                                if (!formRef && !!simpleForm) {
                                    setFormRef(simpleForm);
                                }
                            }}
                            {...formConfig}
                            inited={true}
                            restClient={restClient}
                            hideSubmitButton={true}
                            initValues={formConfig.values}
                        />
                    </div>
                    {!editMode &&
                    <React.Fragment>
                        <Typography variant="h2" component="h2">
                            {'Комментарии'}
                        </Typography>
                        <Comments/>
                        <CommentForm/>
                        <Typography variant="h2" component="h2">
                            {'История изменений'}
                        </Typography>
                        <History/>
                    </React.Fragment>
                    }
                </React.Fragment>
                }
            </DefaultDialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.tickets.ticketIsOpen,
        ticketId: state.tickets.ticketId,
        inited: state.ticket.inited,
        formConfig: state.ticket.formConfig,
        formRef: state.ticket.formRef,
        formTempValues: state.ticket.formTempValues,
        restClient: state.page.elkUser.restClient,
        editMode: state.ticket.editMode,
        status: state.ticket.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeTicket: () => dispatch(ticketsCloseTicket()),
        init: () => dispatch(ticketInit()),
        setFormRef: (formRef) => dispatch(ticketSetFormRef(formRef)),
        mapSetHighLighted: (highLighted) => dispatch(ticketsMapSetHighLighted(highLighted)),
        mapChangeScale: (scale) => dispatch(ticketsMapChangeScale(scale)),
        setFormTempValues: (values) => dispatch(ticketSetFormTempValues(values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ticket));

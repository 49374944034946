import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import ImageMapper from 'react-image-mapper';
import JpgMapImage from './map-orig.jpg';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    ticketsMapCreateTicket,
    ticketsMapSetRef,
    ticketsOpenTicket,
    ticketsMapSetCreateCoords,
    ticketsMapSetShowCreateButton
} from '../../actions/tickets';
import MapAddIcon from './MapAddIcon';
import MapHighLightIcon from './MapHighLightIcon';
import {TICKET_COLORS} from "../../constants/tickets";

const getDistanceBetweenPoints = (pointA, pointB) => (
    Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2))
);

const getPointFromTouch = (touch, element) => {
    const rect = element.getBoundingClientRect();
    return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
    };
};

const ZOOM_RATES = [.5, 0.65, .75, 1., 1.5, 2., 2.5, 3., 4., 5., 6., 7., 8., 9., 10.];

const MAP = {
    name: "my-map",
    areas: []
};

const POINT_SIZE = 20;
const IMAGE_WIDTH = 600;
const INITIAL_SCALE_DESKTOP = 2;
const INITIAL_SCALE_MOBILE = 0.65;

const styles = theme => ({
    progressWrapper: {
        width: '100%',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        position: 'relative',
        marginTop: theme.spacing(),
        width: '100%',
        overflow: 'auto',
        '& img[usemap="#my-map"]': {
            "WebkitFilter": "grayscale(30%)",
            "MozFilter": "grayscale(30%)",
            "MsFilter": "grayscale(30%)",
            "OFilter": "grayscale(30%)",
            "filter": "grayscale(30%)"
        }
    },
    ticket: {
        width: POINT_SIZE,
        height: POINT_SIZE,
        borderRadius: '100%',
        position: 'absolute',
        zIndex: 100,
        border: '2px solid #ffffff',
        cursor: 'pointer'
    },
    createButton: {
        position: 'fixed',
        zIndex: 200,
        left: -1000,
        top: -1000,
        padding: 0
    }
});

class Map extends React.Component {
    constructor(props) {
        super(props);
        const INITIAL_SCALE = this.props.isMobileDevice ? INITIAL_SCALE_MOBILE : INITIAL_SCALE_DESKTOP;
        this.state = {
            scale: INITIAL_SCALE,
            zoomIndex: ZOOM_RATES.findIndex(scale => scale === INITIAL_SCALE)
        };
        this.containerRef = React.createRef();
    }

    hideCreateButton = () => {
        const {mapSetCreateCoords, mapSetShowCreateButton} = this.props;
        mapSetCreateCoords([0, 0]);
        mapSetShowCreateButton(false);
    };

    clickedOutside = (e) => {
        const {mapSetCreateCoords, mapSetShowCreateButton} = this.props, {scale} = this.state;
        let rect = e.target.getBoundingClientRect();
        let createCoords = [
            Math.round((e.clientX - rect.left) / scale),
            Math.round((e.clientY - rect.top) / scale)
        ];
        mapSetCreateCoords(createCoords);
        mapSetShowCreateButton(true, [e.clientX - 23, e.clientY - 45]);
    };

    componentDidMount() {
        document.addEventListener('wheel', this.onWrapperWheel, {passive: false});
        document.addEventListener('touchstart', this.handleTouchStart, {passive: false});
        document.addEventListener('touchmove', this.handleTouchMove, {passive: false});
        document.addEventListener('touchend', this.handleTouchEnd, {passive: false});
        document.addEventListener('gesturestart', this.preventDefaultGesture);
        document.addEventListener('scroll', this.hideCreateButton);
        const {map, mapSetRef} = this.props;
        if (!map.ref) {
            mapSetRef(this);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('wheel', this.onWrapperWheel);
        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchmove', this.handleTouchMove);
        document.removeEventListener('touchend', this.handleTouchMove);
        document.removeEventListener('gesturestart', this.preventDefaultGesture);
        document.removeEventListener('scroll', this.hideCreateButton);
    }

    preventDefaultGesture = (e) => {
        if (!e.target || e.target.nodeName.toLowerCase() !== 'img') {
            e.preventDefault();
        }
    };

    handlePinchStart = (event) => {
        this.hideCreateButton();
        const pointA = getPointFromTouch(event.touches[0], document.body);
        const pointB = getPointFromTouch(event.touches[1], document.body);
        this.startDistance = getDistanceBetweenPoints(pointA, pointB);
    };

    handlePinchMove = (event) => {
        event.preventDefault();
        const pointA = getPointFromTouch(event.touches[0], document.body);
        const pointB = getPointFromTouch(event.touches[1], document.body);
        this.lastDistance = getDistanceBetweenPoints(pointA, pointB);
        // this.lastPoints = [pointA, pointB];
        this.performTouchScale();
    };

    handleTouchStart = (event) => {
        if (!!event.touches && event.touches.length === 2) this.handlePinchStart(event);
    };

    handleTouchMove = (event) => {
        this.hideCreateButton();
        if (!!event.touches && event.touches.length === 2) this.handlePinchMove(event);
    };

    handleTouchEnd = (event) => {
        if (event.touches.length > 0) return null;
        this.performTouchScale();
    };

    performTouchScale = () => {
        if (!!this.startDistance && !!this.lastDistance) {
            const distance = this.startDistance, delta = (distance / this.lastDistance);
            // container = this.containerRef.current;

            let scale = this.state.zoomIndex;

            if (delta > 1.) {
                scale = (scale > 0) ? scale - 1 : scale;
            } else if (delta < 1.) {
                scale = (scale < ZOOM_RATES.length - 1) ? scale + 1 : scale;
            }

            this.changeZoomIndex(scale);
            this.startDistance = 0;
            this.lastDistance = 0;
            // container.scrollLeft = container.scrollLeft / 2 * scale;
            // container.scrollTop = container.scrollTop / 2 * scale;
        }
    };

    onWrapperWheel = (e) => {
        this.hideCreateButton();
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            let delta = e.deltaY || e.detail || e.wheelDelta;
            let scale = this.state.zoomIndex;
            if (delta > 0) {
                scale = (scale > 0) ? scale - 1 : scale;
            } else {
                scale = (scale < ZOOM_RATES.length - 1) ? scale + 1 : scale;
            }
            this.changeZoomIndex(scale);
        }
    };

    changeScale = (scale) => {
        this.setState({scale});
    };

    changeZoomIndex = (index) => {
        this.setState({zoomIndex: index}, () => {
            this.changeScale(ZOOM_RATES[this.state.zoomIndex]);
        });
    };

    render() {
        let {
            classes, map, createTicket, openTicket, isMobileDevice, createCoords, createButtonCoords, showCreateButton
        } = this.props,
            {scale} = this.state;
        const offsetTop = isMobileDevice ? 120 : 210;
        if (!map.inited) {
            return <div className={classes.progressWrapper}><CircularProgress size={40}/></div>;
        }
        return (
            <div style={{paddingTop: scale < 1 ? 30 : 0}}>
                <IconButton
                    className={classes.createButton}
                    style={{
                        left: createButtonCoords[0],
                        top: createButtonCoords[1],
                        display: showCreateButton ? 'block' : 'none'
                    }}
                    onClick={() => createTicket(createCoords[0], createCoords[1])}
                >
                    <MapAddIcon/>
                </IconButton>
                <div
                    className={classes.container}
                    style={{height: document.documentElement.clientHeight - offsetTop}}
                    ref={this.containerRef}
                >
                    {map.tickets.filter(ticket => !!ticket['service_desc-map_x'] && !!ticket['service_desc-map_y']
                        && ticket['service_desc-status_code'] !== 'closed')
                        .map(ticket =>
                            <div
                                key={ticket['service_desc-obj_id']}
                                className={classes.ticket}
                                style={{
                                    left: ticket['service_desc-map_x'] * scale - POINT_SIZE / 2,
                                    top: ticket['service_desc-map_y'] * scale - POINT_SIZE / 2,
                                    backgroundColor: TICKET_COLORS[ticket['service_desc-status_code']]
                                }}
                                onClick={() => {
                                    this.hideCreateButton();
                                    openTicket(ticket['service_desc-obj_id']);
                                }}
                            >&nbsp;
                                {map.highLighted.indexOf(ticket['service_desc-obj_id']) !== -1 && <MapHighLightIcon/>}
                            </div>
                        )}
                    <ImageMapper
                        src={JpgMapImage}
                        map={MAP}
                        width={IMAGE_WIDTH * scale}
                        imgWidth={IMAGE_WIDTH}
                        onImageClick={evt => this.clickedOutside(evt)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        map: state.tickets.map,
        isMobileDevice: state.page.isMobileDevice,
        createCoords: state.tickets.map.createCoords,
        showCreateButton: state.tickets.map.showCreateButton,
        createButtonCoords: state.tickets.map.createButtonCoords,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTicket: (left, top) => dispatch(ticketsMapCreateTicket(left, top)),
        openTicket: (id) => dispatch(ticketsOpenTicket(id)),
        mapSetRef: (ref) => dispatch(ticketsMapSetRef(ref)),
        mapSetCreateCoords: (createCoords) => dispatch(ticketsMapSetCreateCoords(createCoords)),
        mapSetShowCreateButton: (show, coords) => dispatch(ticketsMapSetShowCreateButton(show, coords))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Map));

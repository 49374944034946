import {getUserCoords} from '@ksbteam/core/api/Geolocation';
import {getUrlParams} from "@ksbteam/core/api/UrlManager";

export function ticketsSetCrudConfig(crudConfig) {
    return {
        type: 'TICKETS_SET_CRUD_CONFIG',
        crudConfig
    };
}

export function ticketsSetCrudRef(crudRef) {
    return {
        type: 'TICKETS_SET_CRUD_REF',
        crudRef
    };
}

export function ticketsSetInited(inited) {
    return {
        type: 'TICKETS_SET_INITED',
        inited
    };
}

export function ticketsSetInitialState() {
    return {
        type: 'TICKETS_SET_INITIAL_STATE'
    };
}

export function ticketsSetStatuses(statuses) {
    return {
        type: 'TICKETS_SET_STATUSES',
        statuses
    };
}

export function ticketsInit() {
    return async (dispatch, getState) => {
        const state = getState(), client = state.page.elkUser.restClient, urlParams = getUrlParams();
        if (!!urlParams['ticket_id']) {
            dispatch(ticketsOpenTicket(urlParams['ticket_id']));
        }
        dispatch(ticketsSetInitialState());
        let response = await Promise.all([
            client.get('uk/ticket/config'),
            client.get('uk/dict/status'),
        ]);
        let crudConfig = response[0];
        let statuses = response[1];
        dispatch(ticketsSetStatuses(statuses));
        let createAction = crudConfig.toolbar.actions.find(action => action.code === 'create');
        createAction.form.config = await client.get(createAction.form['config_url']);
        createAction.form.config = ticketsInitFormWidgets(createAction.form.config);
        createAction.dialog.onOpen = () => setCreateFormCoords(dispatch, getState);
        delete createAction.form['config_url'];
        dispatch(ticketsSetCrudConfig(crudConfig));
        dispatch(ticketsSetInited(true));
    };
}

function setCreateFormCoords(dispatch, getState) {
    const state = getState(), crudConfig = Object.assign({}, state.tickets.crudConfig),
        crud = state.tickets.crudRef;
    let formConfig = crudConfig.toolbar.actions.find(action => action.code === 'create').form.config;
    getUserCoords((latitude, longitude) => {
        formConfig.values['service_desc-latitude'] = latitude;
        formConfig.values['service_desc-longitude'] = longitude;
        dispatch(ticketsSetCrudConfig(crudConfig));
        crud.forceUpdate();
    });
}

export function ticketsInitFormWidgets(config) {
    let result = Object.assign({}, config);
    let videoField = config.fields.find(field => field.attrs.name === 'service_desc-video');
    let photoField = config.fields.find(field => field.attrs.name === 'service_desc-photo');
    videoField.uploadUrl = 'uk/file-storage/set-file?project=UK';
    videoField.downloadUrl = 'uk/file-storage/get-body-file?project=UK';
    photoField.uploadUrl = 'uk/file-storage/set-img?project=UK';
    photoField.downloadUrl = 'uk/file-storage/img-from-file?project=UK';
    return result;
}

export function ticketsOpenTicket(ticketId) {
    return async (dispatch, getState) => {
        dispatch(ticketsSetTicketIsOpen(true, ticketId));
    };
}

export function ticketsCloseTicket() {
    return async (dispatch, getState) => {
        const state = getState(), {formRef, formTempValues} = state.ticket, formValues = formRef.getPreparedValues();
        let needConfirm = JSON.stringify(formTempValues) !== JSON.stringify(formValues);
        if (!needConfirm || (needConfirm && window.confirm('Есть несохраненные данные. Закрыть окно?'))) {
            const urlParams = getUrlParams();
            if (!!Object.keys(urlParams).length) {
                window.history.replaceState({}, document.title, window.location.pathname);
            }
            dispatch(ticketsSetTicketIsOpen(false));
        }
    };
}

export function ticketsSetTicketIsOpen(ticketIsOpen, ticketId = null) {
    return {
        type: 'TICKETS_SET_TICKET_IS_OPEN',
        ticketIsOpen,
        ticketId
    };
}

export function ticketsSetFilters(filters) {
    return {
        type: 'TICKETS_SET_FILTERS',
        filters
    };
}

export function ticketsUpdateFilters(filters) {
    return async (dispatch) => {
        dispatch(ticketsSetFilters(filters));
    };
}

export function ticketsApplyFilters() {
    return async (dispatch, getState) => {
        const state = getState(), dataGrid = state.tickets.crudRef.dataGrid;
        dataGrid.updateRows();
    };
}

export function ticketsClearFilters() {
    return {
        type: 'TICKETS_CLEAR_FILTERS'
    };
}

export function ticketsOpenFiltersDialog() {
    return {
        type: 'TICKETS_OPEN_FILTERS_DIALOG'
    };
}

export function ticketsCloseFiltersDialog() {
    return {
        type: 'TICKETS_CLOSE_FILTERS_DIALOG'
    };
}

export function ticketsSetTab(tab) {
    return {
        type: 'TICKETS_SET_TAB',
        tab
    };
}

export function ticketsToggleCreateDialog() {
    return async (dispatch, getState) => {
        const state = getState(), crud = state.tickets.crudRef, {showCreateButton} = state.tickets.map;
        if (!showCreateButton) {
            alert('Выберите точку на схеме');
        } else {
            crud.toggleCreateFormDialog();
        }
    };
}

export function ticketsMapSetInited(inited) {
    return {
        type: 'TICKETS_MAP_SET_INITED',
        inited
    };
}

export function ticketsMapSetTickets(tickets) {
    return {
        type: 'TICKETS_MAP_SET_TICKETS',
        tickets
    };
}

export function ticketsMapCreateTicket(left, top, address) {
    return async (dispatch, getState) => {
        const state = getState(), crud = state.tickets.crudRef,
            crudConfig = Object.assign({}, state.tickets.crudConfig), {showCreateButton} = state.tickets.map;
        if (!showCreateButton) {
            alert('Выберите точку на схеме');
            return;
        }
        let formConfig = crudConfig.toolbar.actions.find(action => action.code === 'create').form.config;
        let photoField = formConfig.fields.find(field => field.attrs.name === 'service_desc-photo');
        photoField.crop = false;
        formConfig.values['service_desc-map_x'] = left;
        formConfig.values['service_desc-map_y'] = top;
        formConfig.values['service_desc-address'] = address;

        console.log('address', address);

        dispatch(ticketsSetCrudConfig(crudConfig));
        crud.toggleCreateFormDialog();
    };
}

export function ticketsMapSetHighLighted(highLighted) {
    return {
        type: 'TICKETS_MAP_SET_HIGHLIGHTED',
        highLighted
    };
}

export function ticketsMapSetRef(ref) {
    return {
        type: 'TICKETS_MAP_SET_REF',
        ref
    };
}

export function ticketsMapChangeScale(scale) {
    return async (dispatch, getState) => {
        // const state = getState(), map = state.tickets.map.ref;
        // // map.changeScale(scale);
    };
}

export function ticketsMapSetCreateCoords(createCoords) {
    return {
        type: 'TICKETS_MAP_SET_CREATE_COORDS',
        createCoords
    };
}

export function ticketsMapSetShowCreateButton(showCreateButton, createButtonCoords = [0, 0]) {
    return {
        type: 'TICKETS_MAP_SET_SHOW_CREATE_BUTTON',
        showCreateButton,
        createButtonCoords
    };
}

import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import TextField from '@ksbteam/core/components/Form/Field/TextField';
import SnackbarError from '@ksbteam/core/components/Snackbar/SnackbarError';
import ElkUser from '../../api/ElkUser';
import {Redirect} from 'react-router';
import {language, t} from '@ksbteam/core/api/DummyTranslate';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    snackbarWrapper: {
        marginBottom: theme.spacing(2)
    },
    fieldError: {
        color: 'red',
        marginTop: theme.spacing(),
    }
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: '',
            loginError: ''
        };
        this.elkUser = new ElkUser();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(values, actions) {
        actions.setSubmitting(true);
        try {
            this.setState({loginError: ''});
            await this.elkUser.login(values.login, values.password);
            window.location.href = `/main`;
            this.setState({redirect: `/main`})
        } catch (error) {
            this.setState({loginError: t('Invalid login or password')});
        }
        actions.setSubmitting(false);
    }

    render() {
        const {classes} = this.props;
        if (!!this.state.redirect) {
            return <Redirect push to={this.state.redirect}/>;
        }
        return (
            <React.Fragment>
                <Formik
                    initialValues={{login: '', password: ''}}
                    onSubmit={(values, actions) => {
                        this.handleSubmit(values, actions);
                    }}
                    validationSchema={Yup.object().shape({
                        login: Yup.string()
                            .required(t('Field is required')),
                        password: Yup.string()
                            .required(t('Field is required')),
                    })}
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <div style={{width: 315, maxWidth: '100%', margin: '100px auto 0'}}>
                                <img style={{width: '85%', margin: '1.5em auto 3em', display: 'block'}}
                                     src={


                                         language === 'ru'
                                         ? 'https://sum1.ru/images/logo.png'
                                         : 'https://sum1.ru/images/logo.png'

                                         // language === 'ru'
                                         //     ? 'https://roscongress.org/bitrix/templates/rc2/img/logo.svg'
                                         //     : 'https://roscongress.org/bitrix/templates/rc2/img/logo_en.svg'
                                     } alt=""/>
                                <Paper className={classes.root} elevation={1}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        style={{
                                            fontWeight: 300,
                                            textAlign: 'center',
                                            marginBottom: '1em'
                                        }}>
                                        {'УК'}
                                    </Typography>
                                    {!!this.state.loginError.length &&
                                    <div className={classes.snackbarWrapper}>
                                        <SnackbarError message={this.state.loginError}/>
                                    </div>
                                    }
                                    <form className={'simple-form'} onSubmit={handleSubmit}>
                                        <div className="simple-form-row" style={{marginBottom: '1em'}}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item xs={2}>
                                                    <PersonIcon/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        id={'login'}
                                                        label={t('Login')}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.login && touched.login &&
                                                    <Typography variant="caption" gutterBottom
                                                                className={classes.fieldError}>
                                                        {errors.login}
                                                    </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="simple-form-row" style={{marginBottom: '1em'}}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item xs={2}>
                                                    <LockOutlined/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        id={'password'}
                                                        label={t('Password')}
                                                        type={'password'}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.password && touched.password &&
                                                    <Typography variant="caption" gutterBottom
                                                                className={classes.fieldError}>
                                                        {errors.password}
                                                    </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <Button
                                            disabled={isSubmitting || !!Object.keys(errors).length}
                                            type={'submit'}
                                            variant="contained"
                                            size="medium"
                                            color="secondary" style={{width: '100%', marginTop: '2em'}}
                                        >
                                            {t('Log in')}
                                        </Button>
                                    </form>
                                </Paper>
                            </div>

                        );
                    }}
                </Formik>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Login);

